import { isArray, reduce } from 'lodash';
import { UserStatus } from 'src/app/enums/UserStatus';
import { IUser } from 'src/app/interfaces/ICommon';
import {
  IDependentOption,
  IGroupToRoleRelation,
  IRolesRows,
  IRolesWithInstancesRows,
} from './interfaces';

export interface IResponseItem {
  response: IGroupToRoleRelation[];
  success?: boolean;
}
export type TGetSuccessResponses = (
  items: IResponseItem[]
) => IGroupToRoleRelation[];

export const getSuccessResponses: TGetSuccessResponses = (items) => {
  return reduce(
    items,
    (acc: IGroupToRoleRelation[], item: IResponseItem) => {
      if (item.success && item.response) {
        acc.push(...item.response);
      }
      return acc;
    },
    []
  );
};

export const sliceTableData = <T>(
  pageIndex: number,
  pageSize: number,
  rows: T[]
): T[] => {
  const from = pageIndex * pageSize;
  const to = (pageIndex + 1) * pageSize;
  return rows.slice(from, to);
};

export const isPendingUserPredicate: (user: IUser) => boolean = (user) => {
  return (
    user.status === UserStatus.STAGED || user.status === UserStatus.PROVISIONED
  );
};

export const isCurrentUserPredicate: (user: IUser) => boolean = (user) => {
  return (
    user.status === UserStatus.ACTIVE ||
    user.status === UserStatus.RECOVERY ||
    user.status === UserStatus.PASSWORD_EXPIRED
  );
};

export const checkProductInstancesSelection = (
  rowOptions: IRolesRows[] | IRolesWithInstancesRows[]
): any => {
  return rowOptions.filter((row) => {
    const roleInstances =
      'instancesOptions' in row &&
      'selectedRole' in row &&
      row.instancesOptions[row.selectedRole ?? ''];

    if (isArray(roleInstances)) {
      const unselectedInstances = roleInstances.filter(
        (instance: IDependentOption) => {
          return !instance.selected;
        }
      );

      return unselectedInstances.length === roleInstances.length;
    }
    return false;
  });
};
