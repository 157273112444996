import { External, InActive, Legacy, Provisioned, Trial } from './constant';
import { ProvisioningStatus } from '../enums/ProvisioningStatus';
import { IGenericTagProps } from '../interfaces/IProductGenericTag';
import { IProductTransformed } from '../interfaces/IProductList';

export const productFlags = (
  productObject: IProductTransformed
): IGenericTagProps[] => {
  const productTags: IGenericTagProps[] = [];
  const conditionsAndTags = [
    { condition: productObject.isTrial, tag: Trial },
    { condition: productObject.isLegacy, tag: Legacy },
    { condition: !productObject.isActive, tag: InActive },
    {
      condition: [
        ProvisioningStatus.IN_PROGRESS,
        ProvisioningStatus.ERROR,
      ].includes(productObject.provisionStatus as ProvisioningStatus),
      tag: Provisioned,
    },
    { condition: productObject.isExternal, tag: External },
  ];

  conditionsAndTags.forEach(({ condition, tag }) => {
    if (condition) {
      productTags.push(tag);
    }
  });
  return productTags;
};
