import { CDSOption } from '@ciscodesignsystems/cds-react-select';
import { AxiosResponse } from 'axios';
import { isArray, some } from 'lodash';
import _toUpper from 'lodash/toUpper';
import { EventChannel, SagaIterator } from 'redux-saga';
import {
  all,
  call,
  delay,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { TabsList } from 'src/app/enums/TabsList';
import { FEATURE_FLAG_PREFIX } from 'src/app/utils/constant';
import {
  AddNotification,
  AddUserToAdminGroup,
  AssignSelectedGroup,
  AssignSelectedGroups,
  AssignSelectedInstance,
  AssignSelectedInstances,
  AssignSelectedRole,
  AssignSelectedRoles,
  ChangeFirstNameFieldFromEditUserDrawer,
  ChangeLastNameFieldFromEditUserDrawer,
  CreateAllUsers,
  CreateGroup,
  DeleteAssignedGroup,
  DeleteAssignedRole,
  EditUserDetails,
  FetchAllGroups,
  FetchAllRoles,
  FetchAssignedGroups,
  FetchAssignedRoles,
  FetchEditedUser,
  FetchGroupsToRolesRelations,
  HideEditUserDetailsDrawer,
  HideNotification,
  RemoveUserFromAdminGroup,
  SetActiveRemoveGroupId,
  SetActiveRemoveRoleId,
  SetActiveRemoveRoleWithInstanceRowData,
  SetAssignRolesTable,
  SetAssignRolesTableDrawer,
  SetAssignRolesWithInstancesTableDrawer,
  SetAssignRolesWithInstancesTableStepper,
  SetGroupsOptionsFromDrawer,
  SetInviteDirty,
  SetInviteStepperPendingToCreateUsers,
  SetInviteUsers,
  SetInviteUsersGroupsOptions,
  SetIsUsersFileUploaded,
  SetNewGroupName,
  SetPristineRolesOptionsFromDrawer,
  SetProductOptionsFromDrawer,
  SetProductsOptions,
  SetRegionFilterOptions,
  SetRolesOptions,
  SetRolesOptionsFromDrawer,
  SetSecurityCloudControlRoleDrawer,
  SetUploadCSVName,
  SetUserIsLoading,
  ShowEditUserDetailsDrawer,
  ShowSuccessCreatedGroupNotification,
  ShowSuccessInviteUsersNotification,
  ToggleAssignGroupsDrawerVisibility,
  ToggleAssignRolesDrawerVisibility,
  ToggleGroupsDrawerVisibility,
  ToggleInviteStepperCreateUsersNotification,
  UploadCSVFile,
  SetAddUsersStep,
  FetchAllUserRolesAssignments,
  SetUsersTableActiveTab,
  ShowErrorInviteUsersNotification,
  HideErrorInviteUsersNotification,
  AssignSelectedInstanceForKPA,
  AssignSelectedInstancesForKpaUser,
  AssignSelectedGroupsForKPA,
  AssignSelectedGroupForKPA,
} from './actions';
import {
  FILE_ERROR,
  FILE_LOADED,
  SHOWS_INVITE_NOTIFICATION_AFTER_MS,
} from './constants';
import {
  IResponseItem,
  getSuccessResponses,
  isCurrentUserPredicate,
  isPendingUserPredicate,
} from './helper';
import {
  EditedUserResponseType,
  IActionWithPayload,
  ICreatedUsers,
  IFileUploadAction,
  IGroupToRoleRelation,
  IGroupWithRoleRelationsResponse,
  IRbacAssignedRole,
  IRbacGroup,
  IRbacRole,
  IUserFormRow,
  OptionsType,
  RbacAssignedGroupsResponseType,
  RbacAssignedRolesResponseType,
  RbacGroupsResponseType,
  RbacRolesResponseType,
  UserInvitationsResponseType,
} from './interfaces';
import {
  IOption,
  getActiveEditUserId,
  getActiveRemoveGroupId,
  getActiveRemoveRoleId,
  getActiveRemoveRoleWithInstanceRowData,
  getAllGroups,
  getAllRoles,
  getAssignedGroups,
  getAssignedRoles,
  getAssignedRolesWithInstancesTableData,
  getDefaultSccRoleFromDrawer,
  getEditUserDetailsDrawerFields,
  getFormFieldsForNewGroup,
  getGroupsOptions,
  getGroupsOptionsFromDrawer,
  getInviteUsers,
  getNewGroupName,
  getOpenedUser,
  getPristineGroupsOptionsFromDrawer,
  getPristineProductOptionsFromDrawer,
  getPristineRolesOptionsFromDrawer,
  getSCCRole,
  getSccRoleFromDrawer,
  getSelectedGroupsIDs,
  getSelectedGroupsOptionsFromDrawer,
  getSelectedInstancesIDsInDrawer,
  getSelectedProductOptionFromDrawer,
  getSelectedRolesIDs,
  getSelectedRolesIDsInAssignRolesDrawer,
  getSelectedRolesIDsWithInstances,
  getUserDetails,
  getUserFromEnterprise,
  getIsKPA,
} from './selectors';
import {
  createFileReaderChannel,
  selectNewlyCreatedGroupOption,
  toProductOptions,
  toRoleOptions,
  transformGroupsToGroupsOptions,
  transformInviteUsers,
  validateUploadChange,
} from './utilities';
import { prometheusAPIClient } from '../../api/clients';
import { EditUserNotificationType } from '../../enums/EditUser';
import { RolesPermission } from '../../enums/RolesPermissionList';
import { IBootstrap, IUser } from '../../interfaces/ICommon';
import { FileReaderEventActionType } from '../../interfaces/IFileReaderEventActions';
import { IProductTransformed } from '../../interfaces/IProductList';
import {
  RbacBundledRolesResponseType,
  RbacCustomRolesResponseType,
} from '../../interfaces/IUserRoles';
import {
  IAssignedRoleWIthInstanceTableRow,
  IEditUserDetailsDrawerFields,
  INewGroupFields,
  IRoleAndInstanceIds,
  IRoleAndTenantIds,
} from '../../interfaces/IUsers';
import * as Paths from '../../paths';
import { notifyFormPopulated } from '../../services/formPopulated';
import {
  extractGroupsOptions,
  extractRolesOptions,
  filterGroups,
  filterUserRoles,
  mapPreviouslySelectedOptions,
} from '../../utils/filter-options';
import { getCombinedRoles } from '../../utils/shared';
import { FetchAllProductsInstances } from '../common/actions';
import { handleFetchAllUsers, handleFetchFeatureFlags } from '../common/saga';
import {
  getAllUsers,
  getBootstrapConfig,
  getFeatureFlagsSet,
  getNavigation,
  getUniqueRegions,
} from '../common/selectors';
import { getEnterpriseId } from '../enterprises/selectors';
import { FETCH_PRODUCTS_LIST_SUCCESS } from '../overview/store/constants';

export function* uploadCSVFile(action: IFileUploadAction): SagaIterator {
  const { info, setFileError } = action.payload;

  const uploadChangeError = validateUploadChange(info);
  if (uploadChangeError) {
    setFileError(uploadChangeError);
    return;
  }

  yield put(SetUserIsLoading(true));
  yield put(SetUploadCSVName(info.file.name));

  const file = info.file.originFileObj as File;

  const fileReaderChannel: EventChannel<FileReaderEventActionType> = yield call(
    () => createFileReaderChannel(file)
  );

  try {
    let eventAction: FileReaderEventActionType;

    while ((eventAction = yield take(fileReaderChannel))) {
      if (
        eventAction.type === FILE_ERROR &&
        typeof eventAction.payload === 'string'
      ) {
        setFileError(eventAction.payload);
        return;
      } else if (
        eventAction.type === FILE_LOADED &&
        typeof eventAction.payload !== 'string' &&
        typeof eventAction.payload !== 'number' &&
        eventAction.payload
      ) {
        yield put(SetInviteUsers(eventAction.payload));
        yield put(SetUserIsLoading(false));
        yield put(SetIsUsersFileUploaded(true));
      } else {
        yield put(eventAction);
      }
    }
  } finally {
    fileReaderChannel.close();
  }
}

export function* handleFinishInviteStepper(
  action: IActionWithPayload<boolean>
): Generator {
  const tenantFeatureFlag = action.payload;

  const { basePath, isStandalone } = (yield select(
    getBootstrapConfig
  )) as IBootstrap;
  const { navigateFn } = (yield select(getNavigation)) as any;
  const { usersAlreadyExistInEnterprise, usersNotExistInEnterprise }: any =
    yield select(getUserFromEnterprise);
  const isUserKPA = yield select(getIsKPA);

  if (usersAlreadyExistInEnterprise.length && isUserKPA) {
    yield put(AssignSelectedInstancesForKpaUser.PENDING());
    yield put(AssignSelectedGroupsForKPA.PENDING());
  }

  if (usersNotExistInEnterprise.length > 0) {
    yield put(CreateAllUsers.PENDING(tenantFeatureFlag));
  }

  const path = `${isStandalone ? '' : basePath}/${Paths.USERS}`;

  yield call(navigateFn, path);
  yield put(SetInviteDirty(false));
  yield put(SetAddUsersStep(1));

  yield delay(SHOWS_INVITE_NOTIFICATION_AFTER_MS);
}

export function* createAllUsers(
  action: IActionWithPayload<boolean>
): Generator {
  const tenantFeatureFlag = action.payload;
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const inviteUsers = (yield select(getInviteUsers)) as IUserFormRow[];
  const sccRole = (yield select(getSCCRole)) as RolesPermission;
  const selectedGroupsIDs = (yield select(getSelectedGroupsIDs)) as string[];
  const selectedRolesIDs = (yield select(getSelectedRolesIDs)) as string[];
  const selectedRolesIDsWithInstances = (yield select(
    getSelectedRolesIDsWithInstances
  )) as IRoleAndTenantIds[];
  const { usersNotExistInEnterprise }: any = yield select(
    getUserFromEnterprise
  );
  const isUserKPA = yield select(getIsKPA);
  // Hide banner with previous error before new invite
  yield put(HideErrorInviteUsersNotification());

  const users =
    isUserKPA && usersNotExistInEnterprise.length
      ? usersNotExistInEnterprise
      : inviteUsers;
  const usersDetailsPayload = transformInviteUsers(users, sccRole);
  try {
    const response: UserInvitationsResponseType =
      yield prometheusAPIClient.post(
        `/api/enterprises/${enterpriseId}/userInvitations`,
        {
          users: usersDetailsPayload,
          groupIds: selectedGroupsIDs,
          [tenantFeatureFlag ? 'roles' : 'roleIds']: tenantFeatureFlag
            ? selectedRolesIDsWithInstances
            : selectedRolesIDs,
        }
      );

    yield put(CreateAllUsers.SUCCESS(response.data));
    // Re-fetch all Users for update information in tabs.
    yield call(handleFetchAllUsers);

    const { errors: invitedUsersErrors, invitedUsersCount } = response.data;
    if (isArray(invitedUsersErrors) && invitedUsersErrors.length) {
      yield put(ShowErrorInviteUsersNotification());
    }
    if (invitedUsersCount) {
      yield put(ShowSuccessInviteUsersNotification());
      yield put(
        SetAssignRolesWithInstancesTableStepper([
          { selectedRole: null, rolesOptions: [], instancesOptions: [] },
        ])
      );

      const refreshedUsers = (yield select(getAllUsers)) as IUser[];
      if (isArray(refreshedUsers)) {
        if (some(refreshedUsers, isPendingUserPredicate)) {
          // Move to tab "Pending Users" in case when we have Users there
          // Also show Notification for that tab
          yield put(SetUsersTableActiveTab(TabsList.PendingUsers));
          yield put(ToggleInviteStepperCreateUsersNotification(true));
        } else if (some(refreshedUsers, isCurrentUserPredicate)) {
          // Move to tab "Current Users" in case when we do not have Pending Users
          yield put(SetUsersTableActiveTab(TabsList.CurrentUsers));
        }
      }
    }
  } catch (error) {
    yield put(CreateAllUsers.ERROR(error as ICreatedUsers));
    yield put(ShowErrorInviteUsersNotification());
  }
}

export function* fetchGroups(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const newGroupName = (yield select(getNewGroupName)) as string;

  try {
    const response: RbacGroupsResponseType = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/rbac/groups`
    );

    // setting groups options
    const groupsOptions = transformGroupsToGroupsOptions(
      response.data.rbacGroups
    );

    // selecting the new group inside group options dropdown
    if (newGroupName) {
      const currentGroupsOptions = (yield select(
        getGroupsOptions
      )) as IOption[];
      yield put(
        SetInviteUsersGroupsOptions(
          selectNewlyCreatedGroupOption(
            groupsOptions,
            currentGroupsOptions,
            newGroupName
          )
        )
      );
      yield put(SetNewGroupName(''));
    } else {
      yield put(SetInviteUsersGroupsOptions(groupsOptions));
    }

    yield put(FetchAllGroups.SUCCESS(response.data.rbacGroups));
  } catch (error) {
    yield put(FetchAllGroups.ERROR(error as Error));
  }
}

export function* createGroup(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const { name, description } = (yield select(
    getFormFieldsForNewGroup
  )) as INewGroupFields;

  try {
    yield prometheusAPIClient.post(
      `/api/enterprises/${enterpriseId}/rbac/groups`,
      {
        name: name.value,
        description: description.value,
      }
    );

    yield put(FetchAllGroups.PENDING());
    yield put(ShowSuccessCreatedGroupNotification());
    yield put(ToggleGroupsDrawerVisibility(false));

    yield put(CreateGroup.SUCCESS());
  } catch (error) {
    yield put(CreateGroup.ERROR(error as Error));
  }
}

export function* fetchRoles(): Generator {
  const enterpriseId = yield select(getEnterpriseId);

  yield call(handleFetchFeatureFlags);
  const featureFlagsSet = (yield select(getFeatureFlagsSet)) as Set<string>;

  try {
    const response: RbacRolesResponseType = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/rbac/roles`
    );
    const customRolesResponse: RbacCustomRolesResponseType =
      yield prometheusAPIClient.get(
        `/api/enterprises/${enterpriseId}/rbac/customRoles`
      );
    const bundledRolesResponse: RbacBundledRolesResponseType =
      yield prometheusAPIClient.get(
        `/api/enterprises/${enterpriseId}/rbac/bundledRoles`
      );

    const rbacRoles = getCombinedRoles(
      response,
      customRolesResponse,
      bundledRolesResponse
    ).filter((role) =>
      new Set(
        role.featureFlags?.map((flag) =>
          _toUpper(flag).replace(FEATURE_FLAG_PREFIX, '')
        )
      ).isSubsetOf(featureFlagsSet)
    );

    const productsOptions = toProductOptions(rbacRoles);
    const rolesOptions = toRoleOptions(rbacRoles);

    yield put(SetProductsOptions(productsOptions));
    yield put(SetRolesOptions(rolesOptions));

    yield put(
      SetAssignRolesTable([
        {
          selectedProduct: null,
          productsOptions,
          rolesOptions,
        },
      ])
    );

    yield put(FetchAllRoles.SUCCESS(rbacRoles));
    yield populateRolesSelect();
  } catch (error) {
    yield put(FetchAllRoles.ERROR(error as Error));
  }
}

export function* populateRolesSelect(): Generator {
  const allRoles = (yield select(getAllRoles)) as IRbacRole[];
  const assignedRoles = (yield select(getAssignedRoles)) as IRbacAssignedRole[];
  const selectedProductOptionFromDrawer = (yield select(
    getSelectedProductOptionFromDrawer
  )) as OptionsType;

  const filteredRoles = filterUserRoles(
    allRoles,
    assignedRoles,
    selectedProductOptionFromDrawer?.value ?? ''
  );
  const options = extractRolesOptions(filteredRoles);

  yield put(SetRolesOptionsFromDrawer(options));
  yield put(SetPristineRolesOptionsFromDrawer(options));
}

export function* fetchAssignedGroupsSucess(): Generator {
  const allGroups = (yield select(getAllGroups)) as IRbacGroup[];
  const assignedGroups = (yield select(getAssignedGroups)) as IRbacGroup[];
  const groupsOptionsFromDrawer = (yield select(
    getGroupsOptionsFromDrawer
  )) as CDSOption[];

  const filteredGroups = filterGroups(allGroups, assignedGroups);
  const extractedOptions = extractGroupsOptions(filteredGroups);
  // to check if there were selected options before refresh
  const options = mapPreviouslySelectedOptions(
    extractedOptions,
    groupsOptionsFromDrawer
  );
  yield put(SetGroupsOptionsFromDrawer(options));
}

export function* fetchAssignedRoles(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const {
    profile: { id },
  } = (yield select(getOpenedUser)) as IUser;

  try {
    const response: RbacAssignedRolesResponseType =
      yield prometheusAPIClient.get(
        `/api/enterprises/${enterpriseId}/rbac/users/${id}/roles`
      );
    const data = response.data.rbacUserRolesAssignments;

    yield put(FetchAssignedRoles.SUCCESS(data));
  } catch (error) {
    yield put(FetchAssignedRoles.ERROR(error as Error));
  }
}

export function* fetchAssignedGroups(
  action: IActionWithPayload<boolean>
): Generator {
  const { payload: shouldFetchRoles = true } = action;

  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const {
    profile: { id },
  } = (yield select(getOpenedUser)) as IUser;

  try {
    const response: RbacAssignedGroupsResponseType =
      yield prometheusAPIClient.get(
        `/api/enterprises/${enterpriseId}/rbac/users/${id}/groups`
      );

    const data = response.data.rbacUserGroupsAssignments;

    yield put(FetchAssignedGroups.SUCCESS(data));

    if (shouldFetchRoles) {
      yield put(FetchGroupsToRolesRelations.PENDING());
    }
  } catch (error) {
    yield put(FetchAssignedGroups.ERROR(error as Error));
  }
}

export function* toggleAssignRolesDrawerVisibility(
  action: IActionWithPayload<boolean>
): Generator {
  const isDrawerVisible = action.payload;
  const defaultSccRoleFromDrawer = yield select(getDefaultSccRoleFromDrawer);
  const userDetails = yield select(getUserDetails);

  if (isDrawerVisible && userDetails) {
    yield put(SetSecurityCloudControlRoleDrawer(defaultSccRoleFromDrawer));
  } else {
    yield take(HideNotification.TYPE);
    yield put(
      SetAssignRolesTableDrawer([
        {
          selectedProduct: null,
          productsOptions: [],
          rolesOptions: {},
        },
      ])
    );
    yield put(
      SetAssignRolesWithInstancesTableDrawer([
        {
          selectedRole: null,
          rolesOptions: [],
          instancesOptions: {},
        },
      ])
    );
  }
}

export function* assignRolesCallbackFn(): Generator {
  yield put(AssignSelectedRoles.SUCCESS());
  yield put(AssignSelectedInstances.SUCCESS());

  yield put(AddNotification(EditUserNotificationType.ASSIGN_ROLES));
  yield put(ToggleAssignRolesDrawerVisibility(false));
  // -- TODO: Add notification

  yield refreshEditUserPage('roles');
}

export function* assignRolesCallbackFnForKPA(): Generator {
  const { usersAlreadyExistInEnterprise } = (yield select(
    getUserFromEnterprise
  )) as { usersAlreadyExistInEnterprise: IUser[] };
  const userIds = usersAlreadyExistInEnterprise.map((user: any) => user.id);

  for (const id of userIds) {
    yield put(FetchAllUserRolesAssignments.PENDING(id));
  }

  yield put(AssignSelectedInstancesForKpaUser.SUCCESS());
  yield put(ShowSuccessInviteUsersNotification());
}

export function* assignSelectedRoles(): Generator {
  const rolesIDs: OptionsType = yield select(
    getSelectedRolesIDsInAssignRolesDrawer
  );
  const sccRole = (yield select(getSccRoleFromDrawer)) as RolesPermission;
  const userDetails = (yield select(getUserDetails)) as IUser;

  if (userDetails.isAdmin && sccRole === RolesPermission.MEMBER) {
    yield put(RemoveUserFromAdminGroup.PENDING());
  }
  if (!userDetails.isAdmin && sccRole === RolesPermission.ADMINISTRATOR) {
    yield put(AddUserToAdminGroup.PENDING());
  }

  if (!rolesIDs.length) {
    yield assignRolesCallbackFn();
  }

  try {
    yield all(
      rolesIDs.map((id: string, index: number) => {
        const callbackFnArg =
          index === rolesIDs.length - 1 ? assignRolesCallbackFn : null;
        return put(
          AssignSelectedRole.PENDING({ id, callbackFn: callbackFnArg })
        );
      })
    );
  } catch (error) {
    yield put(AssignSelectedRoles.ERROR(error as Error));
    yield put(AddNotification(EditUserNotificationType.ERROR));
  }
}

export function* assignSelectedInstances(): Generator {
  const instancesIDs: OptionsType = yield select(
    getSelectedInstancesIDsInDrawer
  );
  const sccRole = (yield select(getSccRoleFromDrawer)) as RolesPermission;
  const userDetails = (yield select(getUserDetails)) as IUser;

  if (userDetails.isAdmin && sccRole === RolesPermission.MEMBER) {
    yield put(RemoveUserFromAdminGroup.PENDING());
  }
  if (!userDetails.isAdmin && sccRole === RolesPermission.ADMINISTRATOR) {
    yield put(AddUserToAdminGroup.PENDING());
  }

  if (!instancesIDs.length) {
    yield assignRolesCallbackFn();
  }

  try {
    yield all(
      instancesIDs.map(
        (roleAndInstanceIds: IRoleAndInstanceIds, index: number) => {
          const callbackFnArg =
            index === instancesIDs.length - 1 ? assignRolesCallbackFn : null;
          return put(
            AssignSelectedInstance.PENDING({
              roleAndInstanceIds,
              callbackFn: callbackFnArg,
            })
          );
        }
      )
    );
  } catch (error) {
    yield put(AssignSelectedInstances.ERROR(error as Error));
    yield put(AddNotification(EditUserNotificationType.ERROR));
  }
}

export function* assignSelectedInstance(
  action: IActionWithPayload<{
    roleAndInstanceIds: IRoleAndInstanceIds;
    callbackFn: Function | null;
  }>
): Generator {
  const {
    payload: { roleAndInstanceIds, callbackFn },
  } = action;
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const {
    profile: { id },
  } = (yield select(getOpenedUser)) as IUser;

  try {
    yield prometheusAPIClient.post(
      `/api/enterprises/${enterpriseId}/rbac/users/${id}/roles`,
      {
        roleId: roleAndInstanceIds.roleId,
        tenantId: roleAndInstanceIds.instanceId,
        enterpriseId,
        userId: id,
      }
    );

    yield put(AssignSelectedInstance.SUCCESS());

    // -- is last item and has callbackFn
    if (callbackFn) {
      yield callbackFn();
    }
  } catch (error) {
    yield put(AssignSelectedInstance.ERROR(error as Error));
  }
}

export function* assignSelectedInstancesForKpaUser(): Generator {
  const selectedRolesIDsWithInstances: OptionsType = yield select(
    getSelectedRolesIDsWithInstances
  );
  const { usersAlreadyExistInEnterprise } = (yield select(
    getUserFromEnterprise
  )) as { usersAlreadyExistInEnterprise: IUser[] };

  if (!selectedRolesIDsWithInstances) {
    yield assignRolesCallbackFnForKPA();
  }

  try {
    const actions = usersAlreadyExistInEnterprise.map((user: any) => {
      return selectedRolesIDsWithInstances.map(
        (roleAndInstanceIds: IRoleAndInstanceIds, index: number) => {
          const profileId = user?.id;

          const callbackFnArg =
            index === selectedRolesIDsWithInstances.length - 1
              ? assignRolesCallbackFnForKPA
              : null;
          return put(
            AssignSelectedInstanceForKPA.PENDING({
              roleAndInstanceIds,
              callbackFn: callbackFnArg,
              profileId,
            })
          );
        }
      );
    });
    yield all(actions.flat());
  } catch (error) {
    yield put(AssignSelectedInstancesForKpaUser.ERROR(error as Error));
  }
}

export function* assignSelectedInstanceForKPA(
  action: IActionWithPayload<{
    roleAndInstanceIds: IRoleAndTenantIds;
    callbackFn: Function | null;
    profileId: string;
  }>
): Generator {
  const {
    payload: { roleAndInstanceIds, callbackFn, profileId },
  } = action;
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  try {
    yield prometheusAPIClient.post(
      `/api/enterprises/${enterpriseId}/rbac/users/${profileId}/roles`,
      {
        roleId: roleAndInstanceIds.roleId,
        tenantId: roleAndInstanceIds.tenantId,
        enterpriseId,
        userId: profileId,
      }
    );
    yield put(AssignSelectedInstance.SUCCESS());

    if (callbackFn) {
      yield callbackFn();
    }
  } catch (error) {
    yield put(AssignSelectedInstance.ERROR(error as Error));
  }
}

export function* assignSelectedRole(
  action: IActionWithPayload<{
    id: string;
    callbackFn: Function | null;
  }>
): Generator {
  const {
    payload: { id: roleId, callbackFn },
  } = action;
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const {
    profile: { id },
  } = (yield select(getOpenedUser)) as IUser;

  try {
    yield prometheusAPIClient.post(
      `/api/enterprises/${enterpriseId}/rbac/users/${id}/roles`,
      {
        roleId,
        enterpriseId,
        userId: id,
      }
    );

    yield put(AssignSelectedRole.SUCCESS());

    // -- is last item and has callbackFn
    if (callbackFn) {
      yield callbackFn();
    }
  } catch (error) {
    yield put(AssignSelectedRole.ERROR(error as Error));
  }
}

export function* addUserToAdminGroup(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const { id } = (yield select(getUserDetails)) as IUser;

  try {
    yield prometheusAPIClient.post(`/api/enterprises/${enterpriseId}/admins`, {
      userId: id,
    });
    yield put(AddUserToAdminGroup.SUCCESS());
    yield refreshEditUserPage('user');
  } catch (error) {
    yield put(AddUserToAdminGroup.ERROR(error as Error));
  }
}

export function* removeUserToAdminGroup(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const { id } = (yield select(getUserDetails)) as IUser;

  try {
    yield prometheusAPIClient.delete(
      `/api/enterprises/${enterpriseId}/admins/${id}`
    );
    yield put(RemoveUserFromAdminGroup.SUCCESS());
    yield refreshEditUserPage('user');
  } catch (error) {
    yield put(RemoveUserFromAdminGroup.ERROR(error as Error));
  }
}

export function* assignGroupsCallbackFn(): Generator {
  yield put(AssignSelectedGroups.SUCCESS());

  yield put(AddNotification(EditUserNotificationType.ASSIGN_TO_GROUPS));
  yield put(ToggleAssignGroupsDrawerVisibility(false));
  // -- TODO: Add notification

  yield refreshEditUserPage('groups');
}

export function* assignSelectedGroups(): Generator {
  const selectedGroupsOptionsFromDrawer: OptionsType = yield select(
    getSelectedGroupsOptionsFromDrawer
  );

  const groupIDs = selectedGroupsOptionsFromDrawer.map(
    (option: IOption) => option.value
  );

  try {
    yield all(
      groupIDs.map((id: string, index: number) => {
        const callbackFnArg =
          index === groupIDs.length - 1 ? assignGroupsCallbackFn : null;
        return put(
          AssignSelectedGroup.PENDING({ id, callbackFn: callbackFnArg })
        );
      })
    );
  } catch (error) {
    yield put(AssignSelectedGroups.ERROR(error as Error));
    yield put(AddNotification(EditUserNotificationType.ERROR));
  }
}

export function* assignSelectedGroup(
  action: IActionWithPayload<{
    id: string;
    callbackFn: Function | null;
  }>
): Generator {
  const {
    payload: { id: groupId, callbackFn },
  } = action;
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const {
    profile: { id },
  } = (yield select(getOpenedUser)) as IUser;

  try {
    yield prometheusAPIClient.post(
      `/api/enterprises/${enterpriseId}/rbac/users/${id}/groups`,
      {
        groupId,
        enterpriseId,
        userId: id,
      }
    );

    yield put(AssignSelectedGroup.SUCCESS());

    // -- is last item and has callbackFn
    if (callbackFn) {
      yield callbackFn();
    }
  } catch (error) {
    yield put(AssignSelectedGroup.ERROR(error as Error));
  }
}

export function* assignGroupsCallbackFnForKPA(): Generator {
  const { usersAlreadyExistInEnterprise } = (yield select(
    getUserFromEnterprise
  )) as { usersAlreadyExistInEnterprise: IUser[] };

  const userIds = usersAlreadyExistInEnterprise.map((user: any) => user.id);

  for (const id of userIds) {
    yield put(FetchAllUserRolesAssignments.PENDING(id));
  }

  yield put(AssignSelectedGroupsForKPA.SUCCESS());
  yield put(ShowSuccessInviteUsersNotification());
}

export function* assignSelectedGroupsForKPA(): Generator {
  const selectedGroupsIDs = (yield select(getSelectedGroupsIDs)) as string[];

  const { usersAlreadyExistInEnterprise }: any = yield select(
    getUserFromEnterprise
  );

  try {
    const actions = usersAlreadyExistInEnterprise.map((user: any) => {
      return selectedGroupsIDs.map((groupId: string, index: number) => {
        const profileId = user?.id;

        const callbackFnArg =
          index === selectedGroupsIDs.length - 1
            ? assignGroupsCallbackFnForKPA
            : null;

        return put(
          AssignSelectedGroupForKPA.PENDING({
            id: groupId,
            callbackFn: callbackFnArg,
            profileId,
          })
        );
      });
    });

    yield all(actions.flat());
  } catch (error) {
    yield put(AssignSelectedGroups.ERROR(error as Error));
    yield put(AddNotification(EditUserNotificationType.ERROR));
  }
}

export function* assignSelectedGroupForKPA(
  action: IActionWithPayload<{
    id: string;
    callbackFn: Function | null;
    profileId: string;
  }>
): Generator {
  const {
    payload: { id: groupId, callbackFn, profileId },
  } = action || {};
  const enterpriseId = (yield select(getEnterpriseId)) as string;

  try {
    yield prometheusAPIClient.post(
      `/api/enterprises/${enterpriseId}/rbac/users/${profileId}/groups`,
      {
        groupId,
        enterpriseId,
        userId: profileId,
      }
    );

    yield put(AssignSelectedGroup.SUCCESS());

    if (callbackFn) {
      yield callbackFn();
    }
  } catch (error) {
    yield put(AssignSelectedGroup.ERROR(error as Error));
  }
}

export function* deleteAssignedRole(
  action: IActionWithPayload<boolean>
): Generator {
  const hasTenantFeatureFlag = action.payload;
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const userId = yield select(getActiveEditUserId);
  const roleId = yield select(getActiveRemoveRoleId);

  const activeRemoveInstance = (yield select(
    getActiveRemoveRoleWithInstanceRowData
  )) as IAssignedRoleWIthInstanceTableRow | null;

  try {
    yield prometheusAPIClient.delete(
      `/api/enterprises/${enterpriseId}/rbac/users/${userId}/roles/${
        hasTenantFeatureFlag ? activeRemoveInstance?.id : roleId
      }`
    );

    yield put(DeleteAssignedRole.SUCCESS());
    yield put(AddNotification(EditUserNotificationType.REMOVE_FROM_ROLE));

    yield refreshEditUserPage('roles');
  } catch (error) {
    yield put(DeleteAssignedRole.ERROR(error as Error));
  }
}

export function* deleteAssignedGroup(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const userId = yield select(getActiveEditUserId);
  const groupId = yield select(getActiveRemoveGroupId);

  try {
    yield prometheusAPIClient.delete(
      `/api/enterprises/${enterpriseId}/rbac/users/${userId}/groups/${groupId}`
    );

    yield put(DeleteAssignedGroup.SUCCESS());
    yield put(AddNotification(EditUserNotificationType.REMOVE_FROM_GROUP));

    yield refreshEditUserPage('groups');
  } catch (error) {
    yield put(DeleteAssignedGroup.ERROR(error as Error));
  }
}

export function* handleFetchProductsListSuccess(
  action: IActionWithPayload<{ allProducts: IProductTransformed[] }>
): Generator {
  // -- TODO: Remove when necessary products for enterprise are created on backend
  const productOptions = [
    { label: 'Firewall', value: 'Firewall' },
    { label: 'XDR', value: 'XDR' },
    { label: 'DUO', value: 'DUO' },
    { label: 'Endpoint', value: 'Endpoint' },
  ];

  yield put(SetProductOptionsFromDrawer(productOptions));
}

export function* clearDrawers(target: string): Generator {
  const pristineProductOptionsFromDrawer = yield select(
    getPristineProductOptionsFromDrawer
  );
  const pristineRolesOptionsFromDrawer = yield select(
    getPristineRolesOptionsFromDrawer
  );
  const pristineGroupsOptionsFromDrawer = yield select(
    getPristineGroupsOptionsFromDrawer
  );

  if (target === 'roles') {
    yield put(ToggleAssignRolesDrawerVisibility(false));
    yield put(SetProductOptionsFromDrawer(pristineProductOptionsFromDrawer));
    yield put(SetRolesOptionsFromDrawer(pristineRolesOptionsFromDrawer));
  }

  if (target === 'groups') {
    yield put(ToggleAssignGroupsDrawerVisibility(false));
    yield put(SetGroupsOptionsFromDrawer(pristineGroupsOptionsFromDrawer));
  }
}

export function* refreshEditUserPage(target: string): Generator {
  if (target === 'user') {
    const { id } = (yield select(getUserDetails)) as IUser;
    yield put(FetchEditedUser.PENDING(id));
  }
  if (target === 'roles') {
    yield put(FetchAllRoles.PENDING());
    yield put(FetchAssignedRoles.PENDING());

    yield take(HideNotification.TYPE);
    yield put(SetActiveRemoveRoleId(null));
    yield put(SetActiveRemoveRoleWithInstanceRowData(null));
    yield clearDrawers('roles');
  }

  if (target === 'groups') {
    yield put(FetchAllGroups.PENDING());
    yield put(FetchAssignedGroups.PENDING());

    yield take(HideNotification.TYPE);
    yield put(SetActiveRemoveGroupId(null));
    yield clearDrawers('groups');
  }
}

export function* fetchEditedUser(
  action: IActionWithPayload<string>
): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const currentUserId = action.payload;

  try {
    const response: EditedUserResponseType = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/users/${currentUserId}`
    );
    yield put(FetchEditedUser.SUCCESS(response.data));
  } catch (error) {
    yield put(FetchEditedUser.ERROR(error as Error));
    yield put(AddNotification(EditUserNotificationType.ERROR));
  }
}

export function* handleShowEditUserDetailsDrawer(): Generator {
  const { profile } = (yield select(getUserDetails)) as IUser;
  yield put(
    ChangeFirstNameFieldFromEditUserDrawer({
      value: profile.firstName,
      error: false,
      touched: false,
    })
  );
  yield put(
    ChangeLastNameFieldFromEditUserDrawer({
      value: profile.lastName,
      error: false,
      touched: false,
    })
  );
}

export function* editUserDetails(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const userDetails = (yield select(getUserDetails)) as IUser;
  const { firstName, lastName } = (yield select(
    getEditUserDetailsDrawerFields
  )) as IEditUserDetailsDrawerFields;
  try {
    yield prometheusAPIClient.put(
      `/api/enterprises/${enterpriseId}/users/${userDetails.id}`,
      {
        ...userDetails.profile,
        firstName: firstName.value,
        lastName: lastName.value,
      }
    );

    yield put(EditUserDetails.SUCCESS());
    yield put(FetchEditedUser.PENDING(userDetails.id));
    yield put(HideEditUserDetailsDrawer());
    yield put(AddNotification(EditUserNotificationType.EDIT_NAME));
  } catch (error) {
    yield put(EditUserDetails.ERROR(error as Error));
    yield put(AddNotification(EditUserNotificationType.ERROR));
  }
}

// TODO: API call should be changed when we have single endpoint
export interface IGetAllGroupToRolesRelationsResp {
  data: {
    rbacGroupRoles: IGroupToRoleRelation[];
  };
}

export function* handleFetchGroupToRolesRelations(
  group: IRbacGroup
): Generator {
  const enterpriseId = yield select(getEnterpriseId);
  try {
    const response: IGetAllGroupToRolesRelationsResp | any =
      yield prometheusAPIClient.get(
        `/api/enterprises/${enterpriseId}/rbac/groups/${group.id}/roles`
      );
    return {
      response: response.data.rbacGroupRoles,
      success: true,
    };
  } catch (error) {
    return {
      response: error as Error,
    };
  }
}

// Temporary solution until we do not have single endpoint for get all relations between selected user role and groups
export function* handleFetchGroupsToRolesRelations(): Generator {
  const allGroups = (yield select(getAllGroups)) as IRbacGroup[];
  const result: IResponseItem[] | any = yield all(
    allGroups.map((group: IRbacGroup) =>
      call(handleFetchGroupToRolesRelations, group)
    )
  );
  const groupsByRoles: IGroupToRoleRelation[] = getSuccessResponses(result);

  yield put(FetchGroupsToRolesRelations.SUCCESS(groupsByRoles));
}

export function* handleSetInviteDirty(
  action: IActionWithPayload<boolean>
): Generator {
  const { payload: isDirty } = action;

  yield delay(500);
  yield call(notifyFormPopulated, { isDirty });
}

export function* handleFetchAllProductsInstances(): Generator {
  const tableData = (yield select(
    getAssignedRolesWithInstancesTableData
  )) as IRbacAssignedRole[];
  const uniqueRegions: CDSOption[] = (yield select(
    getUniqueRegions
  )) as CDSOption[];
  if (uniqueRegions.length && tableData.length) {
    yield put(SetRegionFilterOptions(uniqueRegions));
  }
}

export function* fetchAllUserRolesAssignments(
  action: IActionWithPayload<string>
): Generator {
  const userId = action.payload;
  const enterpriseId = yield select(getEnterpriseId);

  try {
    const rolesResponse = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/rbac/users/${userId}/roles`
    );

    const groupsWithRolesResponse = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/rbac/users/${userId}/groupsWithRoleRelations`
    );

    const groupsWithRoles = (
      groupsWithRolesResponse as AxiosResponse<IGroupWithRoleRelationsResponse>
    ).data.rbacGroups;

    const assignedRoles = (
      rolesResponse as AxiosResponse<{
        rbacUserRolesAssignments: IRbacAssignedRole[];
      }>
    ).data.rbacUserRolesAssignments;

    yield put(
      FetchAllUserRolesAssignments.SUCCESS({
        groupsWithRoles,
        userId,
        assignedRoles,
      })
    );
  } catch (error: any) {
    yield put(FetchAllUserRolesAssignments.ERROR({ error, userId }));
  }
}

export function* usersSaga(): Generator {
  yield takeEvery(UploadCSVFile.TYPE, uploadCSVFile);
  yield takeLatest(
    SetInviteStepperPendingToCreateUsers.TYPE,
    handleFinishInviteStepper
  );
  yield takeLatest(CreateAllUsers.Pending.TYPE, createAllUsers);
  yield takeLatest(FetchAllGroups.Pending.TYPE, fetchGroups);
  // ----------------
  // TODO: temporary solution until we do not have endpoint which returns "Group - Roles" relations
  yield takeLatest(
    FetchGroupsToRolesRelations.Pending.TYPE,
    handleFetchGroupsToRolesRelations
  );
  // ----------------
  // yield takeLatest(FetchAllGroups.Success.TYPE, fetchGroupsSuccess);
  yield takeLatest(CreateGroup.Pending.TYPE, createGroup);
  yield takeLatest(FetchAllRoles.Pending.TYPE, fetchRoles);
  yield takeEvery(
    FetchAllUserRolesAssignments.Pending.TYPE,
    fetchAllUserRolesAssignments
  );
  yield takeLatest(AssignSelectedGroups.Pending.TYPE, assignSelectedGroups);
  yield takeLatest(AssignSelectedGroup.Pending.TYPE, assignSelectedGroup);
  yield takeLatest(
    AssignSelectedGroupsForKPA.Pending.TYPE,
    assignSelectedGroupsForKPA
  );
  yield takeLatest(
    AssignSelectedGroupForKPA.Pending.TYPE,
    assignSelectedGroupForKPA
  );
  yield takeLatest(AddUserToAdminGroup.Pending.TYPE, addUserToAdminGroup);
  yield takeLatest(
    RemoveUserFromAdminGroup.Pending.TYPE,
    removeUserToAdminGroup
  );
  yield takeLatest(FetchAssignedRoles.Pending.TYPE, fetchAssignedRoles);
  yield takeLatest(AssignSelectedRoles.Pending.TYPE, assignSelectedRoles);
  yield takeLatest(AssignSelectedRole.Pending.TYPE, assignSelectedRole);
  yield takeLatest(
    AssignSelectedInstances.Pending.TYPE,
    assignSelectedInstances
  );
  yield takeLatest(AssignSelectedInstance.Pending.TYPE, assignSelectedInstance);
  yield takeLatest(
    AssignSelectedInstancesForKpaUser.Pending.TYPE,
    assignSelectedInstancesForKpaUser
  );
  yield takeLatest(
    AssignSelectedInstanceForKPA.Pending.TYPE,
    assignSelectedInstanceForKPA
  );
  yield takeLatest(DeleteAssignedRole.Pending.TYPE, deleteAssignedRole);
  yield takeLatest(DeleteAssignedGroup.Pending.TYPE, deleteAssignedGroup);
  yield takeLatest(FetchAssignedGroups.Pending.TYPE, fetchAssignedGroups);
  yield takeLatest(FetchAssignedGroups.Success.TYPE, fetchAssignedGroupsSucess);
  yield takeLatest(FETCH_PRODUCTS_LIST_SUCCESS, handleFetchProductsListSuccess);
  yield takeEvery(SetProductOptionsFromDrawer.TYPE, populateRolesSelect);
  yield takeLatest(FetchEditedUser.Pending.TYPE, fetchEditedUser);
  yield takeEvery(
    ShowEditUserDetailsDrawer.TYPE,
    handleShowEditUserDetailsDrawer
  );
  yield takeEvery(
    ToggleAssignRolesDrawerVisibility.TYPE,
    toggleAssignRolesDrawerVisibility
  );
  yield takeLatest(EditUserDetails.Pending.TYPE, editUserDetails);
  yield takeLatest(
    [FetchAllProductsInstances.Success.TYPE, FetchAssignedRoles.Success.TYPE],
    handleFetchAllProductsInstances
  );
  yield takeLatest(SetInviteDirty.TYPE, handleSetInviteDirty);
}
