import {
  put,
  // select,
  takeLatest,
} from 'redux-saga/effects';
// import { ActivityLogsResponseType } from 'src/app/interfaces/IActivityLog';
import { FetchFilteredActivityLog } from './actions';
import { GENERATED_MOCK_ACTIVITY_LOG } from './constants';
// import { getAppliedFilters } from './selectors';
// import { prometheusAPIClient } from '../../api/clients';
// import { getEnterpriseId } from '../enterprises/selectors';

export function* handleFetchFilteredActivityLog(): Generator {
  // const enterpriseId = yield select(getEnterpriseId);
  // const appliedFilters = yield select(getAppliedFilters);

  try {
    /* --- This part should be used for integration with BE --- */
    /*
    const filteredActivityLogData: ActivityLogsResponseType =
      yield prometheusAPIClient.get(
        `/api/enterprises/${enterpriseId}/audit-logs`,
        // for "parameters" correct work in file "src/app/api/clients.ts"
        // "config.params = null;" should be changed to 
        // "config.params.domain = null;"
        {
          params: appliedFilters
        }
      );

    yield put(FetchFilteredActivityLog.SUCCESS(filteredActivityLogData));
  */

    /* --- TMP solution - should be removed after integration with BE --- */
    yield put(
      FetchFilteredActivityLog.SUCCESS({
        currentPage: 1,
        totalPages: 1,
        totalRecords: 7,
        logs: GENERATED_MOCK_ACTIVITY_LOG.slice(0, 6),
      })
    );
  } catch (error) {
    yield put(FetchFilteredActivityLog.ERROR(error as Error));
  }
}

export function* activityLogSaga(): Generator {
  yield takeLatest(
    FetchFilteredActivityLog.Pending.TYPE,
    handleFetchFilteredActivityLog
  );
}
