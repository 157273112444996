import { CDSOption } from '@ciscodesignsystems/cds-react-select';
import { RolesPermission } from 'src/app/enums/RolesPermissionList';
import {
  IRbacRole,
  IRbacRoleAssignments,
  IRbacGroup,
  IRbacUserGroupAssignments,
  ICreatedUsers,
  IRolesRows,
  ISecurityCloudControlOption,
  IGroupToRoleRelation,
  IOption,
  IFileUpload,
  IUserFormRow,
  IRolesWithInstancesRows,
} from './interfaces';
import {
  getActionFactory,
  getAsyncActionFactory,
} from '../../core/redux/utils/action-class-factory';
import { UserStepperStates } from '../../enums/AddUsersStepper';
import { EditUserNotificationType } from '../../enums/EditUser';
import { TabsList } from '../../enums/TabsList';
import { ISortingOptions, IUser } from '../../interfaces/ICommon';
import {
  INewGroupFields,
  IRoleAndInstanceIds,
  ITablePagination,
  TStepperInviteUsersMethod,
} from '../../interfaces/IUsers';

const createAction = getActionFactory('USERS');
const createAsyncAction = getAsyncActionFactory('USERS');

/* ----------------- async actions ----------------- */
export const CreateAllUsers = createAsyncAction<
  boolean,
  ICreatedUsers,
  ICreatedUsers
>('CREATE_ALL_USERS');

export const FetchAllRoles = createAsyncAction<null, IRbacRole[]>(
  'FETCH_ALL_ROLES'
);

export const FetchAssignedRoles = createAsyncAction<
  null,
  IRbacRoleAssignments[]
>('FETCH_ASSIGNED_ROLES');

export const AssignSelectedRoles = createAsyncAction<null, null>(
  'ASSIGN_SELECTED_ROLES'
);

export const AssignSelectedInstances = createAsyncAction<null, null>(
  'ASSIGN_SELECTED_INSTANCES'
);

export const AssignSelectedInstancesForKpaUser = createAsyncAction<null, null>(
  'ASSIGN_SELECTED_INSTANCES_FOR_KPA'
);

export const AssignSelectedInstance = createAsyncAction<
  { roleAndInstanceIds: IRoleAndInstanceIds; callbackFn?: Function | null },
  null
>('ASSIGN_SELECTED_INSTANCE');

export const AssignSelectedInstanceForKPA = createAsyncAction<
  {
    roleAndInstanceIds: IRoleAndInstanceIds;
    callbackFn?: Function | null;
    profileId: string | null;
  },
  null
>('ASSIGN_SELECTED_INSTANCE_FOR_KPA');

export const AssignSelectedRole = createAsyncAction<
  { id: string; callbackFn?: Function | null },
  null
>('ASSIGN_SELECTED_ROLE');

export const DeleteAssignedRole = createAsyncAction<boolean, null>(
  'DELETE_ASSIGNED_ROLE'
);

export const FetchAllGroups = createAsyncAction<null, IRbacGroup[]>(
  'FETCH_ALL_GROUPS'
);

export const AssignSelectedGroups = createAsyncAction<string[], null>(
  'ASSIGN_SELECTED_GROUPS'
);

export const AssignSelectedGroupsForKPA = createAsyncAction<string[], null>(
  'ASSIGN_SELECTED_GROUPS_FOR_KPA'
);

export const AssignSelectedGroupForKPA = createAsyncAction<
  { id: string; callbackFn?: Function | null; profileId: string | null },
  null
>('ASSIGN_SELECTED_GROUP_FOR_KPA');

export const AssignSelectedGroup = createAsyncAction<
  { id: string; callbackFn?: Function | null },
  null
>('ASSIGN_SELECTED_GROUP');

export const DeleteAssignedGroup = createAsyncAction<string, null>(
  'DELETE_ASSIGNED_GROUP'
);

export const CreateGroup = createAsyncAction<null, null>('CREATE_GROUP');

export const FetchAssignedGroups = createAsyncAction<
  boolean | null,
  IRbacUserGroupAssignments[]
>('FETCH_ASSIGNED_GROUPS');

export const FetchEditedUser = createAsyncAction<string, IUser>(
  'FETCH_EDITED_USER'
);

export const EditUserDetails = createAsyncAction<null, IUser>(
  'EDIT_USER_DETAILS'
);

export const AddUserToAdminGroup = createAsyncAction<null, null>(
  'ADD_USER_TO_ADMIN_GROUP'
);

export const RemoveUserFromAdminGroup = createAsyncAction<null, null>(
  'REMOVE_USER_FROM_ADMIN_GROUP'
);
export const FetchGroupsToRolesRelations = createAsyncAction<
  null,
  IGroupToRoleRelation[]
>('users/FETCH_GROUPS_TO_ROLES_RELATIONS');

export const FetchAllUserRolesAssignments = createAsyncAction<
  string,
  any,
  { error: Error; userId: string }
>('FETCH_ALL_USER_ROLES_ASSIGNMENTS');

/* ----------------- sync actions ------------------ */
export const SetUsersTableActiveTab = createAction<TabsList>(
  'SET_CURRENT_USERS_TABLE_ACTIVE_TAB'
);

export const EditUser = createAction<string>('EDIT_USER');

export const SetActiveRemoveRoleId = createAction<string>(
  'SET_ACTIVE_REMOVE_ROLE_ID'
);

export const SetActiveRemoveRoleWithInstanceRowData = createAction<string>(
  'SET_ACTIVE_REMOVE_ASSIGNED_INSTANCE_ROW_DATA'
);

export const SetActiveRemoveGroupId = createAction<string>(
  'SET_ACTIVE_REMOVE_GROUP_ID'
);

export const SetRolesOptionsFromDrawer = createAction<CDSOption[]>(
  'SET_ROLES_OPTIONS_FROM_DRAWER'
);

export const SetPristineRolesOptionsFromDrawer = createAction<CDSOption[]>(
  'SET_PRISTINE_ROLES_OPTIONS_FROM_DRAWER'
);

export const SetGroupsOptionsFromDrawer = createAction<CDSOption[]>(
  'SET_GROUPS_OPTIONS_FROM_DRAWER'
);

export const SetProductOptionsFromDrawer = createAction<CDSOption[]>(
  'SET_PRODUCT_OPTIONS_FROM_DRAWER'
);

export const SetInviteUsersGroupsOptions = createAction<CDSOption[]>(
  'SET_INVITE_USERS_GROUPS_OPTIONS'
);

export const ToggleAssignRolesDrawerVisibility = createAction<boolean>(
  'TOGGLE_ASSIGN_ROLES_DRAWER_VISIBILITY'
);

export const ToggleAssignGroupsDrawerVisibility = createAction<boolean>(
  'TOGGLE_ASSIGN_GROUPS_DRAWER_VISIBILITY'
);

export const AddNotification =
  createAction<EditUserNotificationType>('ADD_NOTIFICATION');

export const HideNotification = createAction('HIDE_NOTIFICATION');

export const ClearUserDetails = createAction<IUser | null>(
  'CLEAR_USER_DETAILS'
);

export const ShowEditUserDetailsDrawer = createAction(
  'SHOW_EDIT_USER_DETAILS_DRAWER'
);
export const HideEditUserDetailsDrawer = createAction(
  'HIDE_EDIT_USER_DETAILS_DRAWER'
);

export const ChangeFirstNameFieldFromEditUserDrawer = createAction(
  'CHANGE_FIRST_NAME_FIELD_FROM_EDIT_USER_DRAWER'
);
export const ChangeLastNameFieldFromEditUserDrawer = createAction(
  'CHANGE_LAST_NAME_FIELD_FROM_EDIT_USER_DRAWER'
);

export const SetAssignRolesTableDrawer = createAction<IRolesRows[]>(
  'SET_ASSIGN_ROLES_TABLE_DRAWER'
);

export const SetAssignRolesWithInstancesTableDrawer = createAction<
  IRolesRows[]
>('SET_ASSIGN_ROLES_WITH_INSTANCES_TABLE_DRAWER');

export const SetAssignRolesWithInstancesTableStepper = createAction<
  IRolesWithInstancesRows[]
>('SET_ASSIGN_ROLES_WITH_INSTANCES_TABLE_STEPPER');

export const SetSecurityCloudControlRoleDrawer = createAction<
  ISecurityCloudControlOption[]
>('SET_SECURITY_CLOUD_CONTROL_ROLE_DRAWER');

export const SetProductFilterOptions = createAction<IOption[]>(
  'SET_PRODUCT_FILTER_OPTIONS'
);

export const SetProductFilterSelectedOption = createAction<IOption>(
  'SET_PRODUCT_FILTER_SELECTED_OPTION'
);

export const SetRegionFilterOptions = createAction<IOption[]>(
  'SET_REGION_FILTER_OPTIONS'
);

export const SetRegionFilterSelectedOption = createAction<IOption>(
  'SET_REGION_FILTER_SELECTED_OPTION'
);

export const SetTypesFilterOptions = createAction<IOption[]>(
  'SET_TYPES_FILTER_OPTIONS'
);
export const SetTypesFilterSelectedOption = createAction<IOption>(
  'SET_TYPES_FILTER_SELECTED_OPTION'
);

export const SetAssignedRolesTablePagination = createAction<ITablePagination>(
  'SET_ASSIGNED_ROLES_TABLE_PAGINATION'
);

export const SetGroupsTablePagination = createAction<ITablePagination>(
  'SET_GROUPS_TABLE_PAGINATION'
);

export const SetAssignedRolesTableSearch = createAction<string>(
  'SET_ASSIGNED_ROLES_TABLE_SEARCH'
);

export const SetGroupsTableSearch = createAction<string>(
  'SET_GROUPS_TABLE_SEARCH'
);

export const SetUserIsLoading = createAction<boolean>('SET_USER_LOADING');
export const SetUserError = createAction<string | null>('SET_USER_ERROR');

export const ToggleGroupsDrawerVisibility = createAction<boolean>(
  'TOGGLE_GROUP_DRAWER_VISIBILITY'
);

export const SetIsUsersFileUploaded = createAction<boolean>(
  'SET_IS_USERS_FILE_UPLOADED'
);

export const SetInviteUsers = createAction<IUserFormRow[]>('SET_INVITE_USERS');

export const SetNewGroupName = createAction<string>('SET_NEW_GROUP_NAME');

export const SetProductsOptions = createAction<IOption[]>(
  'SET_PRODUCT_OPTIONS'
);
export const SetRolesOptions = createAction<IOption[]>('SET_ROLES_OPTIONS');

export const SetAssignRolesTable = createAction<IRolesRows[]>(
  'SET_ASSIGN_ROLES_TABLE'
);

export const SetSecurityCloudControlRole = createAction<RolesPermission>(
  'SET_SECURITY_CLOUD_CONTROL_ROLE'
);

export const ShowIncompleteUserDataModal = createAction(
  'SHOW_INCOMPLETE_USER_DATA_MODAL'
);
export const HideIncompleteUserDataModal = createAction(
  'HIDE_INCOMPLETE_USER_DATA_MODAL'
);

export const ShowSuccessCreatedGroupNotification = createAction(
  'SHOW_SUCCESS_CREATED_GROUP_NOTIFICATION'
);
export const HideSuccessCreatedGroupNotification = createAction(
  'HIDE_SUCCESS_CREATED_GROUP_NOTIFICATION'
);

export const ShowErrorInviteUsersNotification = createAction(
  'SHOW_ERROR_INVITE_USERS_NOTIFICATION'
);
export const HideErrorInviteUsersNotification = createAction(
  'HIDE_ERROR_INVITE_USERS_NOTIFICATION'
);

export const DiscardChanges = createAction('DISCARD_CHANGES');

export const UploadCSVFile = createAction<IFileUpload>('users/UPLOAD_CSV_FILE');

export const SetUploadCSVName = createAction<string>(
  'users/SET_UPLOAD_CSV_NAME'
);

export const SetUploadCSVProgress = createAction<number>(
  'users/SET_UPLOAD_CSV_PROGRESS'
);

export const SetInviteStepperStep = createAction<UserStepperStates>(
  'SET_INVITE_STEPPER_STEP'
);

export const SetInviteStepperPendingToCreateUsers = createAction<boolean>(
  'SET_INVITE_STEPPER_PENDING_CREATE_USERS'
);

export const SetIsPending = createAction<boolean>('SET_IS_PENDING');

export const ToggleInviteStepperCreateUsersNotification = createAction<boolean>(
  'TOGGLE_INVITE_STEPPER_CREATE_USERS_NOTIFICATION'
);

export const ShowSuccessInviteUsersNotification = createAction<boolean>(
  'SHOW_SUCCESS_INVITE_USERS_NOTIFICATION'
);

export const HideSuccessInviteUsersNotification = createAction<boolean>(
  'HIDE_SUCCESS_INVITE_USERS_NOTIFICATION'
);

export const ClearPersistUsersStepperState = createAction<boolean>(
  'CLEAR_PERSIST_USERS_STEPPER_STATE'
);

export const ClearInitialUsersStepperState = createAction<boolean>(
  'CLEAR_INITIAL_USERS_STEPPER_STATE'
);

export const ChangeNameForNewGroup = createAction<
  Partial<Pick<INewGroupFields, 'name'>>
>('CHANGE_NAME_FOR_NEW_GROUP');

export const ChangeDescriptionForNewGroup = createAction<
  Pick<INewGroupFields, 'description'>
>('CHANGE_DESCRIPTION_FOR_NEW_GROUP');

export const ToggleDiscardChangesModal = createAction<boolean>(
  'TOGGLE_DISCARD_CHANGES_MODAL'
);

export const SetStepperInviteUsersMethod =
  createAction<TStepperInviteUsersMethod>('SET_STEPPER_INVITE_USERS_METHOD');

export const SetInviteDirty = createAction<boolean>('SET_INVITE_DIRTY');

export const DiscardInviteChanges = createAction<{ shouldRedirect?: boolean }>(
  'DISCARD_INVITE_CHANGES'
);

export const ClearUsers = createAction('CLEAR_USERS');

export const SetAssignedRolesTableSorting =
  createAction<ISortingOptions | null>('SET_ASSIGNED_ROLES_TABLE_SORTING');

export const SetGroupsTableSorting = createAction<ISortingOptions | null>(
  'SET_GROUPS_TABLE_SORTING'
);

export const SetAddUsersStep = createAction<number>('SET_ADD_USERS_STEP');

export const ClearFetchingUserRolesIds = createAction(
  'CLEAR_FETCHING_USER_ROLES_IDS'
);

export const ClearRoleAssignments = createAction('CLEAR_ROLE_ASSIGNMENTS');

export const SetCurrentUserId = createAction<string>('SET_CURRENT_USER_ID');
