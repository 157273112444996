import {
  IActivityLogFilters,
  IFilteredActivityLogData,
} from 'src/app/interfaces/IActivityLog';
import {
  getActionFactory,
  getAsyncActionFactory,
} from '../../core/redux/utils/action-class-factory';

const createAction = getActionFactory('ACTIVITY_LOG');
const createAsyncAction = getAsyncActionFactory('ACTIVITY_LOG');

/* ----------------- async actions ----------------- */
export const FetchFilteredActivityLog = createAsyncAction<
  null,
  IFilteredActivityLogData
>('FETCH_FILTERED_ACTIVITY_LOG');

/* ----------------- sync actions ------------------ */
export const SetAppliedFilters = createAction<IActivityLogFilters>(
  'SET_ACTIVITY_LOG_APPLIED_FILTERS'
);
