import { AnyAction } from 'redux';
import { createReducer } from 'redux-create-reducer';

import { IActivityLogState } from 'src/app/interfaces/IActivityLog';
import { FetchFilteredActivityLog, SetAppliedFilters } from './actions';

export const initialState: IActivityLogState = {
  appliedFilters: {
    page: 1,
  },
  filteredActivityLogData: {
    currentPage: 0,
    totalPages: 0,
    totalRecords: 0,
    logs: [],
  },
};

export default createReducer(initialState, {
  [FetchFilteredActivityLog.Pending.TYPE]: (state: IActivityLogState) => ({
    ...state,
    filteredActivityLogData: {
      ...initialState.filteredActivityLogData,
    },
  }),

  [FetchFilteredActivityLog.Success.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    filteredActivityLogData: payload,
  }),

  [FetchFilteredActivityLog.Error.TYPE]: (state: IActivityLogState) => ({
    ...state,
    filteredActivityLogData: {
      ...initialState.filteredActivityLogData,
    },
  }),

  [SetAppliedFilters.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    appliedFilters: {
      ...state.appliedFilters,
      ...payload,
    },
  }),
});
