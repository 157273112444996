import { range } from 'lodash';
import { FilterSubEventType } from 'src/app/enums/ActivityLog';
import { IActivityLog } from 'src/app/interfaces/IActivityLog';

// This mocks should be moved after integration with BE
// to "/spec/unit/app/components/activity-log/mocks.ts"

export const deviceOSs = [
  'Windows 11',
  'Windows 10',
  'Windows 7',
  'Linux Ubuntu',
  'Linux Gentoo',
  'FreeBSD',
  'OpenBSD',
  'Solaris',
  'MacOS',
];
const randomWords = [
  'Lorem',
  'ipsum',
  'dolor',
  'sit',
  'amet,',
  'consectetur',
  'adipiscing',
  'elit,',
  'sed',
  'do',
  'eiusmod',
  'tempor',
  'incididunt',
  'ut',
  'labore',
  'et',
  'dolore',
  'magna',
  'aliqua',
  'Ut',
  'enim',
  'ad',
  'minim',
  'veniam,',
  'quis',
  'nostrud',
  'exercitation',
  'ullamco',
  'laboris',
  'nisi',
  'ut',
  'aliquip',
  'ex',
  'ea',
  'commodo',
  'consequat',
  'duis',
  'aute',
  'irure',
  'dolor',
  'in',
  'reprehenderit',
  'in',
  'voluptate',
  'velit',
  'esse',
  'cillum',
  'dolore',
  'eu',
  'fugiat',
  'nulla',
  'pariatur',
  'excepteur',
  'sint',
  'occaecat',
  'cupidatat',
  'non',
  'proident,',
  'sunt',
  'in',
  'culpa',
  'qui',
  'officia',
  'deserunt',
  'mollit',
  'anim',
  'id',
  'est',
  'laborum',
];
const reversedRandomWords = randomWords.reverse();
const eventTypes = [
  FilterSubEventType.ADMIN_USER_LOGIN_SUCCESS,
  FilterSubEventType.ADMIN_USER_LOGIN_FAILURE,
  FilterSubEventType.IDP_USER_CREATED,
];
const users = [
  'arcu.morbi@google.com',
  'dolor.sit@amet.consectetur',
  'ipsum.ac@icloud.org',
  'occaecat.quis@hotmail.com',
  'iaculis.quis@hotmail.com',
  'magna.cras@outlook.com',
  'mestagna.laborum@outlook.com',
  'sunt.cupidatat@outlook.com',
];

const generateRandomSentance: (
  currentIndex: number,
  wordsInSentance: number,
  words: string[]
) => string = (currentIndex, wordsInSentance, words) => {
  return words.slice(currentIndex / words.length, wordsInSentance).join(' ');
};

const getWordFromList: (currentIndex: number, words: string[]) => string = (
  currentIndex,
  words
) => {
  return words[currentIndex / words.length];
};

const generateDate: (currentIndex: number) => string = (currentIndex) => {
  const baseDate = new Date(new Date().valueOf() - currentIndex * 1000 * 60);
  const dateWithoutMiliSeconds = new Date(baseDate)
    .toISOString()
    .replace(/\.\d+Z/, 'Z');

  return dateWithoutMiliSeconds;
};

// This mocks should be moved  after integration with BE to /spec/unit/app/components/activity-log/mocks.ts
export const GENERATED_MOCK_ACTIVITY_LOG: IActivityLog[] = (() => {
  const emptyArray = range(0, 70, 1);

  return emptyArray.map((index: number) => {
    return {
      description: generateRandomSentance(4, index, randomWords),
      deviceOs: getWordFromList(index, deviceOSs),
      eventDetails: generateRandomSentance(7, index, reversedRandomWords),
      eventType: getWordFromList(index, eventTypes),
      id: `uuid_${index}`,
      timestamp: generateDate(index),
      user: getWordFromList(index, users),
    };
  });
})();
