import { createReducer } from 'redux-create-reducer';
import {
  SET_ENTERPRISE_DETAILS,
  SET_ENTERPRISE_CREATED,
  FETCH_ENTERPRISE_DETAILS_PENDING,
  FETCH_ENTERPRISE_DETAILS_SUCCESS,
  FETCH_ENTERPRISE_DETAILS_ERROR,
  FETCH_ENTERPRISES_PENDING,
  FETCH_ENTERPRISES_SUCCESS,
  FETCH_ENTERPRISES_ERROR,
  RESET_ENTERPRISE_DETAILS,
  CREATE_ENTERPRISE_PENDING,
  CREATE_ENTERPRISE_SUCCESS,
  CREATE_ENTERPRISE_ERROR,
  SHOW_SUCCESS_CREATE_ENTERPRISE_BANNER,
  HIDE_SUCCESS_CREATE_ENTERPRISE_BANNER,
  PRE_SELECT_ENTERPRISE,
  OPEN_ENTERPRISE_MODAL,
  CLOSE_ENTERPRISE_MODAL,
  SET_DEFAULT_ENTERPRISE_ERROR,
  SET_DEFAULT_ENTERPRISE_SUCCESS,
  SET_DEFAULT_ENTERPRISE_PENDING,
  SET_DEFAULT_ENTERPRISE_USER_ID,
  HIDE_SUCCESS_SWITCH_ENTERPRISE_TOAST,
} from './constants';
import { IEnterprises } from './interfaces';

export const initialState: IEnterprises = {
  allEnterprises: [],
  error: null,
  isLoading: false,
  enterpriseDetails: {
    id: null,
    name: null,
  },
  enterpriseCreated: false,
  createEnterpriseError: null,
  isCreateEnterpriseLoading: false,
  isSuccessCreateEnterpriseBannerVisible: false,
  isModalOpened: false,
  preSelectedEnterprise: null,
  isDefaultEnterpriseLoaded: false,
  defaultEnterpriseUserId: '',
};

export default createReducer(initialState, {
  [FETCH_ENTERPRISES_PENDING]: (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [FETCH_ENTERPRISES_SUCCESS]: (state, { payload }) => ({
    ...state,
    allEnterprises: payload,
    isLoading: false,
    error: null,
  }),
  [FETCH_ENTERPRISES_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [SET_ENTERPRISE_DETAILS]: (state, { payload }) => ({
    ...state,
    enterpriseDetails: {
      ...state.enterpriseDetails,
      ...payload,
      name: payload.name || 'Default Enterprise',
    },
    isLoading: false,
    error: null,
  }),
  [SET_ENTERPRISE_CREATED]: (state, { payload: isCreated }) => ({
    ...state,
    enterpriseCreated: isCreated,
  }),
  [FETCH_ENTERPRISE_DETAILS_PENDING]: (state) => ({
    ...state,
    enterpriseDetails: {
      ...state.enterpriseDetails,
    },
    isLoading: true,
    error: null,
  }),
  [FETCH_ENTERPRISE_DETAILS_SUCCESS]: (state, { payload }) => ({
    ...state,
    enterpriseDetails: {
      ...state.enterpriseDetails,
      ...payload,
    },
    isLoading: false,
    error: null,
  }),
  [FETCH_ENTERPRISE_DETAILS_ERROR]: (state, { payload }) => ({
    ...state,
    enterpriseDetails: {
      ...state.enterpriseDetails,
    },
    error: payload,
    isLoading: false,
  }),

  [CREATE_ENTERPRISE_PENDING]: (state) => ({
    ...state,
    isCreateEnterpriseLoading: true,
    createEnterpriseError: null,
  }),
  [CREATE_ENTERPRISE_SUCCESS]: (state, { payload }) => ({
    ...state,
    enterpriseDetails: {
      ...state.enterpriseDetails,
      ...payload,
    },
    isCreateEnterpriseLoading: false,
    createEnterpriseError: null,
  }),
  [CREATE_ENTERPRISE_ERROR]: (state, { payload }) => ({
    ...state,
    isCreateEnterpriseLoading: false,
    createEnterpriseError: payload,
  }),
  [SHOW_SUCCESS_CREATE_ENTERPRISE_BANNER]: (state) => ({
    ...state,
    isSuccessCreateEnterpriseBannerVisible: true,
  }),
  [HIDE_SUCCESS_CREATE_ENTERPRISE_BANNER]: (state) => ({
    ...state,
    isSuccessCreateEnterpriseBannerVisible: false,
  }),
  [HIDE_SUCCESS_SWITCH_ENTERPRISE_TOAST]: (state) => ({
    ...state,
  }),
  [OPEN_ENTERPRISE_MODAL]: (state) => ({
    ...state,
    isModalOpened: true,
  }),
  [CLOSE_ENTERPRISE_MODAL]: (state) => ({
    ...state,
    isModalOpened: false,
    preSelectedEnterprise: null,
  }),
  [PRE_SELECT_ENTERPRISE]: (state, { payload }) => ({
    ...state,
    preSelectedEnterprise: payload,
  }),

  [RESET_ENTERPRISE_DETAILS]: (state) => ({
    ...initialState,
    isSuccessCreateEnterpriseBannerVisible:
      state.isSuccessCreateEnterpriseBannerVisible,
  }),
  [SET_DEFAULT_ENTERPRISE_PENDING]: (state) => ({
    ...state,
    isDefaultEnterpriseLoaded: false,
    error: null,
  }),
  [SET_DEFAULT_ENTERPRISE_SUCCESS]: (state, { payload }) => ({
    ...state,
    enterpriseDetails: {
      ...state.enterpriseDetails,
      ...payload,
    },
    isDefaultEnterpriseLoaded: true,
    error: null,
  }),
  [SET_DEFAULT_ENTERPRISE_ERROR]: (state, { payload }) => ({
    ...state,
    isDefaultEnterpriseLoaded: false,
    error: payload,
  }),
  [SET_DEFAULT_ENTERPRISE_USER_ID]: (state, { payload }) => ({
    ...state,
    defaultEnterpriseUserId: payload,
  }),
});
